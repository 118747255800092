<template>
  <div>
    <!-- button -->
    <b-button style="width: 180px" v-b-modal.modal-prevent-closing variant="btn btn-primary">

      <feather-icon icon="PlusIcon" /> Nouveau Projet
    </b-button>

    <!-- modal -->
    <b-modal centered id="modal-prevent-closing" ref="my-modal" title="Demande d'un nouveau projet" ok-title="Envoyer"
      cancel-title="Annuler" cancel-variant="outline-secondary" hide-footer no-close-on-backdrop hide-header-close>
      <validation-observer ref="addProjectForm">
        <form ref="form">
          <b-form-group>
            
            <ky-input :placeholder="'Titre du projet'" :type="'text'" :rules="'required'" v-model="project.name" />
          </b-form-group>
          <div>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <ky-input :placeholder="'Date de debut'" :type="'date'" :rules="'required'"
                    v-model="project.start_date" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <ky-input :placeholder="'Date de fin'" :type="'date'" :rules="'required'"
                    v-model="project.end_date" />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-form-group>
            <b-textarea placeholder="Description du porjet" v-model="project.description">
            </b-textarea>
          </b-form-group>
          <b-form-group class="text-center">
            <button class="btn btn-outline-danger mr-1" @click.prevent="hideModal">
              Fermer
            </button>
            <b-button @click="addClientProject" :disabled="isLoading">
              <b-spinner small variant="light" v-if="isLoading == true"  type="grow"
                label="Spinning"></b-spinner>
              Valider
            </b-button>
          </b-form-group>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import KyInput from "@/@core/layouts/components/ky-input.vue";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      isLoading: false,
      customer: JSON.parse(localStorage.getItem('customerInfo')),
      projectDefault: {
        name: "",
        start_date: "",
        end_date: "",
        desciption: "",
      },
      project: {
        name: "",
        start_date: "",
        end_date: "",
        description: "",
        customer_id: "",
      },
      errors: "",
      errorMessage: "",
      name: "",
      nameState: null,
      submittedNames: [],
    };
  },
  components: {
    ToastificationContent,
    BButton,
    KyInput,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
  },

  computed: {
    ...mapState("customer", ["projects"]),
  },
  methods: {
    async addClientProject() {
      const valid = await this.$refs.addProjectForm.validate();
      if (!valid) return;
      this.loading = true;
      this.isLoading = true
      this.project.customer_id = this.customer.id;
      this.createProject(this.$formData(this.project))
        .then(() => {
          this.isLoading = false;
          this.loading = true;
          this.hideModal();
          this.clearForm();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Demande envoyé avec succès ",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.loading = true;
          this.errorModal = true;
        });
    },

    clearForm() {
      this.project = this.projectDefault;
      this.$nextTick().then(() => {
        this.$refs.addProjectForm.reset();
      });
    },

    hideModal() {
      this.$bvModal.hide("modal-prevent-closing");
    },

    ...mapActions("customer", ["createProject"]),
  },
};
</script>
 